import stressors from "./stressors.json";

// TODO should this be determined at content load time and see what the longest video is?
const maxLengthValue = 3900;  // 3 hours in seconds

const defaultContentFilters = {
  "License Type": [],
  "Category": [],
  "Stressor": [],
  "Series": [],
  "Speaker": [],
  "Media Type": [],
  "Hide Fully Watched Content": false,
  "Max Length": maxLengthValue,
  // "Minimum Length": 0,
  "CE Credit Eligible": false,
  "Text Search": ""
};

const allStressors = stressors.map(s => s.topic);

const allCategories = [
  "Introduction",
  "The Impact of Diversity, Equity and Inclusion in Medicine",
  "Your First Job",
  "Burnout Basics",
  "Burnout Basics for Nurses",
  "Get Home Sooner",
  "Disaster-Proof Your Leadership Style",
  "Work-life Balance",
  "Leadership Skills for Every Nurse",
  "Leadership Skills: Nurse Leaders",
  "Power Tools",
  "Interprofessional Relationships",
  "Belonging and Bias",
  "Laying the Foundation",
  "Extraordinary Times Survival Guide",
  "Financial Lifecycle of Physicians",
  "Eye of the Storm",
  "Physicians On Purpose Podcast",
  "Healing After Adverse Outcomes",
  "Self-care for the Caregiver",
  "Malpractice Recovery",
  "The Flourishing Nurse Podcast Series",
  "Burnout in Women Clinicians",
  "Compassion Fatigue",
  "Working Nights",
  "Getting Back to the Basics",
];

const allLicenseTypes = ['Physicians', 'Nurses', 'Advanced Practice Providers'];

const allMediaTypes = ["Video", "Audio"];

export {
  defaultContentFilters,
  allCategories,
  allStressors,
  allLicenseTypes,
  allMediaTypes,
  maxLengthValue
};