import {
  SET_FILTERS,
  // FETCHING_RESULTS,
  // RESULTS_FETCH_SUCCESS,
  // RESULTS_FETCH_FAILURE,
  FETCHING_HISTORICAL_RESULTS,
  HISTORICAL_RESULTS_FETCH_FAILURE,
  HISTORICAL_RESULTS_FETCH_SUCCESS,
  ADD_FILTER_SET,
  REMOVE_FILTER_SET,
  RESET_BURNOUT_BLOCK,
  RESET_GROUP
} from "../_actions/_types.actions";
import {yearsInNursingWyoming, yearsInProfession} from "../../_helpers/user_options";

export const defaultAgeMax = 1000;
export const defaultAgeMin = 0;

// TODO should this info be managed by the rails server?
//  its needed for filtering results and benchmarks for when license_filter is "All Physicians","All Nurses", or "All Advanced Practice Providers"
// export const physicians = [
//   "Medical Doctor",
//   "Doctor of Osteopathic Medicine",
//   "Doctor of Optometry",
//   "Doctor of Podiatric Medicine",
//   "Physician"
// ];
// export const advanced_practice_providers = [
//   "Certified Nurse Midwife",
//   "Certified Registered Nurse Anesthetist",
//   "Certified Registered Nurse Practitioner",
//   "Physician Assistant",
//   "Advanced Practice Provider",
// ];
// export const nurses = [
//   "Licensed Practical Nurse",
//   "Registered Nurse",
//   "Nurse"
// ];
// export const other = [
//   "Dentist",
//   "Doctor of Philosophy",
//   "EEG Technician",
//   "Nursing Assistant (CNA, PCT)",
//   "Occupational Therapist",
//   "Optometrist",
//   "Pharmacist",
//   "Pharmacy Technician",
//   "Physical Therapist",
//   "Radiology Technician",
//   "Registered Dietitian",
//   "Respiratory Therapist",
//   "Speech Therapist",
//   "Surgical Technician"
// ];

const initialFilters = {
  ageMax: defaultAgeMax,
  ageMin: defaultAgeMin,
  location: "All",
  specialty: "All",
  license: "All",
  gender: "All",
  pilot_status: new Set([true, false, null]),
  configurable_demographic_filters: {}
};

const initialState = {
  historicalRaw: {},
  filters: [Object.assign({},initialFilters)],
  fetchingResults: false,
  resultsFetchError: null
};

export default function results(state = initialState, action) {
  let newState = {};
  switch (action.type) {
    /* OLD WAY OF FETCHING RESULTS BLOCK BY BLOCK
    case FETCHING_RESULTS:
      newState = Object.assign({}, state,{fetchingResults: action.status,});
      return newState;
    case RESULTS_FETCH_FAILURE:
      newState = Object.assign({}, state, {resultsFetchError: action.error});
      return newState;
    case RESULTS_FETCH_SUCCESS:
      newState = Object.assign({}, state, {
        raw: action.data,
        locations: [
          "All",
          ...[...new Set(
            action.data.map(r => {
              return r.provider.location;
            })
          )].sort()
        ],
        specialties: [
          "All",
          ...[...new Set(
            action.data.map(r => {
              return r.provider.specialty;
            })
          )].sort()
        ],
        licenses: [
          "All",
          ...[...new Set(
            action.data.map(r => {
              return r.provider.license;
            })
          )].filter(r => r !== null).sort()
        ],
        resultsFetchError: null
      });
      return newState;

     */
    case FETCHING_HISTORICAL_RESULTS:
      newState = Object.assign({}, state,{fetchingResults: action.status,});
      return newState;
    case HISTORICAL_RESULTS_FETCH_FAILURE:
      newState = Object.assign({}, state, {resultsFetchError: action.error});
      return newState;
    case HISTORICAL_RESULTS_FETCH_SUCCESS:
      newState = Object.assign({}, state, {historicalRaw: action.data});
      return newState
    case SET_FILTERS:
      let filters = state.filters.slice();
      filters[action.filterSet] = Object.assign({},filters[action.filterSet], action.filterAction);
      newState = Object.assign({}, state, {filters});
      return newState;
    case ADD_FILTER_SET:
      let add_f = state.filters.slice();
      add_f.push(initialFilters);
      return Object.assign({},state,{filters: add_f});
    case REMOVE_FILTER_SET:
      //TODO fix this (is not working)
      let remove_f = state.filters.slice();
      remove_f.slice(action.setNum,1);
      return Object.assign({},state,{filters: remove_f});
    case RESET_BURNOUT_BLOCK:
      return Object.assign({},initialState);
    case RESET_GROUP:
      return Object.assign({},initialState);
    // case SELECT_BURNOUT_BLOCK:
    //   let raw = state.historicalRaw[`${action.block.id}`]
    //   return Object.assign({},state,{raw})
    default:
      return state;
  }
}

/*
SELECTOR FUNCTIONS
These following functions are pretty much selector functions so we could memonized these with https://github.com/reduxjs/reselect
 */
export function filterResults(results, filterSet = -1,blockId = 0) {
  const {historicalRaw, filters} = results;
  let responses = []

  if(historicalRaw && historicalRaw[blockId.toString()]) {
    responses = historicalRaw[blockId.toString()]
  }
  const {
    gender,
    ageMax,
    ageMin,
    location,
    specialty,
    license,
    pilot_status,
    configurable_demographic_filters
  } = filterSet === -1 ? initialFilters : filters[filterSet];
  // console.log(filterSet, initialFilters, filters,configurable_demographic_filters, responses);
  return responses.filter(r => {
    return (
      r.responses &&
      r.responses?.length > 0 &&
      ( gender === 'All' || gender === r.provider.gender ) &&
      ( ageMin === defaultAgeMin && ageMax === defaultAgeMax ? true : r.provider.age <= ageMax && r.provider.age >= ageMin ) &&
      ( location === 'All' || r.provider.location === location ) &&
      ( specialty === 'All' || r.provider.specialty === specialty ) &&
      ( license === 'All' || r.provider.license === license ) &&
      ( pilot_status.has(r.provider.is_pilot) )
      && Object.entries(configurable_demographic_filters).every(d => {
        const demographic_question_id = parseInt(d[0]);
        const demographic_value = d[1]
        // console.log(demographic_question_id, r.responses.find(q => q.question_id === demographic_question_id))
        const response = r.responses.find(q => q.question_id === demographic_question_id)
        return response && response.choice === demographic_value;
      })

    );
  });
}

// Rule of 5 defined here. Change this value to make Rule of 6 or w/e
export const num_results_needed = 5;

export const ageRanges = [[0,27],[28, 34], [35, 44], [45, 54], [55, 69], [70, 100]];

// This function finds what filters can be used by checking if
// the filter would cause less than 5 (num_results_needed) total results to be returned
export function getPossibleFilters(results, blockId, demographics, filterSet = -1) {
  const {locations, specialties, licenses, genders, configurable_demographics} = demographics;
  const {filters} = results;
  const {gender, ageMax, ageMin, location, specialty, license, pilot_status, configurable_demographic_filters} = filterSet === -1 ? initialFilters : filters[filterSet];
  let possible_filters = {};

  const possible_genders = genders.filter(gen =>
    filterResults(
      Object.assign({},results,{filters:[{gender: gen, ageMax, ageMin, location, specialty, license, pilot_status, configurable_demographic_filters}]}),
      0,
      blockId
    ).length >= num_results_needed
  );

  // clever way to push the other possible filter locations and specialties
  const possible_locations = locations.filter(loc =>
    filterResults(
      Object.assign({},results,{filters:[{gender, ageMax, ageMin, location: loc, specialty, license, pilot_status, configurable_demographic_filters}]}),
      0,
      blockId
    ).length >= num_results_needed
  );

  const possible_specialties = specialties.filter(spec =>
    filterResults(
      Object.assign({},results,{filters: [{gender, ageMax, ageMin, location, specialty: spec, license, pilot_status, configurable_demographic_filters}]}),
      0,
      blockId
    ).length >= num_results_needed
  );

  const possible_licenses = licenses.filter(pt =>
    filterResults(
      Object.assign({},results,{filters:[{gender, ageMax, ageMin, location, specialty, license: pt, pilot_status, configurable_demographic_filters}]}),
      0,
      blockId
    ).length >= num_results_needed
  );

  const possible_ageRanges = ageRanges.filter(ar =>
    filterResults(
      Object.assign({},results,{filters:[{gender, ageMax: ar[1], ageMin: ar[0], location, specialty, license, pilot_status, configurable_demographic_filters}]}),
      0,
      blockId
    ).length >= num_results_needed
  );

  // // should pilot status
  // possible_filters.pilot_status = [];
  // if(filterResults(
  //   Object.assign({},results,{filters:[{gender, ageMax, ageMin, location, specialty, license, pilot_status: new Set([true])}]}),
  //   0,
  //   blockId
  // ).length >= num_results_needed)
  // {
  //   possible_filters.pilot_status.push(true);
  // }
  //
  // if(filterResults(
  //   Object.assign({},results,{filters:[{gender, ageMax, ageMin, location, specialty, license, pilot_status: new Set([false])}]}),
  //   0,
  //   blockId
  // ).length >= num_results_needed)
  // {
  //   possible_filters.pilot_status.push(false);
  // }

  // handle the new configurable_demographics
  const possible_configurable_demographics = {};
  configurable_demographics.forEach(demographic => {
    possible_configurable_demographics[demographic.base_question_id] = demographic.choices.filter( c =>
      filterResults(
        Object.assign({},results,{
          filters:[{
            gender,
            ageMax,
            ageMin,
            location,
            specialty,
            license,
            pilot_status,
            configurable_demographic_filters: Object.assign({}, configurable_demographic_filters, {[demographic.base_question_id]: c})}]
        }),
        0,
        blockId
      ).length >= num_results_needed
    );
  });

  

  // console.log(possible_locations, possible_specialties, possible_ageRanges);

  possible_filters.locations = possible_locations;
  possible_filters.specialties = possible_specialties;
  possible_filters.licenses = possible_licenses;
  possible_filters.genders = possible_genders;
  possible_filters.ageRanges = [[defaultAgeMin, defaultAgeMax]].concat(possible_ageRanges);
  possible_filters.configurable_demographics = possible_configurable_demographics;

  return possible_filters;
}

// Drying up this code since it is used by the Filters component as well
export function ageRangeToString(ageMin, ageMax) {
  return ageMin === defaultAgeMin ?
    (ageMax === defaultAgeMax ? "All" : "Under " + (ageMax+1)) :
    (ageMax === 100 ? "Over " + (ageMin-1) : ageMin + "-" + ageMax)
}

// moving the gathering of the demographics for the filter dropdown
// out of state and into a selector function.
export function getDemographics(results, demographicQuestions, blockId) {
  // const demographicQuestions
  // console.log(demographicQuestions);
  const {historicalRaw} = results;
  const responses = historicalRaw[blockId] ?? [];
  const locations = new Set();
  const specialties = new Set();
  const licenses = new Set();
  const genders = new Set();
  const configurableDemographics = [];
  let has_pilot_users = false;

  demographicQuestions.forEach(question => {
    // TODO will need to modify this if we ever use a free text demographic question that does not have choices
    // configurableDemographics[question.short_question] = {base_question_id: question?.id, choices: question?.choices.map(c => c.choice)}
    configurableDemographics.push({label: question?.short_question, base_question_id: question?.id, choices: question?.choices.map(c => c.choice)})
  })

  // console.log(configurableDemographics);

  responses.forEach( r => {
    if (r.responses && r.responses.length > 0) {
      locations.add(r.provider.location);
      specialties.add(r.provider.specialty);
      licenses.add(r.provider.license);
      genders.add(r.provider.gender);
    }
    if(r.provider && r.provider.is_pilot) {
      has_pilot_users = true;
    }
  });


  // make sure these are at the top of the license filter dropdown
  // so add them in manually below.
  // licenses.delete('Physician');
  // licenses.delete('Nurse');

  // janky way to get custom specialty list order for Wyoming and ANA since they are showing years in nursing
  let specialtiesOverride = [];
  if (blockId === 111) { // Wyoming School Nurses
    specialtiesOverride = yearsInNursingWyoming;
  } else if (blockId === 103 || blockId === 96 || blockId === 119 || blockId === 123 ) { // ANA
    specialtiesOverride = ["All", ...yearsInProfession];
  } else {
    specialtiesOverride = ["All", ...[...specialties].sort()]
  }

  return {
    locations: [
      "All",
      ...[...locations].sort()
    ],
    specialties: specialtiesOverride,
    licenses: [
      "All",
      // "Physician",
      // "Nurse",
      // "All Advanced Practice Providers",
      ...[...licenses].filter(r => r !== null).sort()
    ],
    genders: ["All", ...[...genders].filter(r => r !== null).sort()],
    has_pilot_users: has_pilot_users,
    configurable_demographics: configurableDemographics
  };
}


export function getCurrentFilters(results, filterSet, blockId) {
  const {gender,location,specialty,ageMin,ageMax,license, pilot_status, configurable_demographic_filters} = results.filters[filterSet];
  return {
    gender,
    location,
    specialty,
    ageRange: ageRangeToString(ageMin,ageMax),
    license,
    configurable_demographic_filters,
    pilot_status: pilot_status.size !== 1 ?
     "All" : (pilot_status.values().next().value === true ? ("Pilot"):("Control"))
    };
}

export function getDataSetName(results, filterSet ,blockId) {
  const currentFilters = Object.entries(getCurrentFilters(results, filterSet,blockId)).filter( f => f[1] !== "All");
  return currentFilters.length > 0 ? currentFilters.map(f =>f[1]).join(', ') : 'Organization';
}

//TODO This is where we will mark certain dates as Q1, Q2, etc.
export function getHistoricalDataSetName(results, burnout_blocks) {
  const {burnoutBlocks} = burnout_blocks;
  const temp = burnoutBlocks.map(b => b.created_at);
  temp.reverse();
  return temp;
}

//aggregate filtered results for highcharts that show frequency of choices for a given question
export function getFrequencyData(results, question, blockId, filterSet = -1) {
  if (!question) {
    return []
  }
  const filtered = filterResults(results,filterSet,blockId);
  const data = [];
  const aggData = new Map();
  const total = filtered.length;

  if (question.choices) {
    question.choices.forEach(c => aggData.set(c.choice,0));
    filtered.forEach(result => {
      const response = result.responses.find(r => r.question_id === question.id);
      if (response && response.choice) {
        const n = aggData.get(response.choice);
        aggData.set(response.choice, n+1);
      } else {
        // console.log(question, result)
      }
    });
  } else if (question.type === "FreeTextQuestion" ) {
    //  pull choices from given text_responses
    // TODO this code block is specifically designed for the "hours worked" question,
    //  in which its expecting the text response given to be a number.
    //  There will need to be some refactoring done if we wish to do a frequency analysis of another free text question.
    filtered.forEach(res => {
      const response = Math.round(parseFloat(res.responses.find(r => r.question_id === question.id).text_response));
      if (aggData.has(response)) {
        const n = aggData.get(response);
        aggData.set(response, n + 1);
      } else {
        aggData.set(response, 1);
      }
    });
  }

  aggData.forEach((value,key) => {
    const frequency = total > 0 ? ((value/total)*100) : 0;
    data.push({
      name: key,
      y: parseFloat(frequency.toFixed(process.env.REACT_APP_CHART_PRECISION)),
      response_count: value
    });
  });

  data.sort((a,b) => a.name - b.name);

  return data;
}


//Doctor is burned out if they chose choices with scores 50 or below on the burnout question
// TODO make future proof in case in future surveys a score range of [0,1,2,3,4] is used instead of [0,25,50,75,100]
export function getPresenceOfBurnoutRatio(results, blockId, filterSet = -1) {
  const filtered = filterResults(results, filterSet, blockId);
  // console.log(results,filterSet,blockId,filtered,parseInt(process.env.REACT_APP_BURNOUT_QUESTION));
  const total = filtered.length;
  let burned = 0, not_burned = 0;

  for (let result of filtered){
    const response = result.responses.find(r => r.question_id === parseInt(process.env.REACT_APP_BURNOUT_QUESTION));
    if (!response) continue;
    response.score > 50 ? not_burned++ : burned++ ;
  }

  const burned_freq = total > 0 ? (burned/total)*100 : 0;
  const not_burned_freq = total > 0 ? 100.0 - burned_freq : 0;

  return [{
    name: 'Presence of Burnout',
    y: parseFloat(burned_freq.toFixed(process.env.REACT_APP_CHART_PRECISION)),
    response_count: burned,
    // color: "#d82841"
  },{
    name: 'No Presence of Burnout',
    y: parseFloat(not_burned_freq.toFixed(process.env.REACT_APP_CHART_PRECISION)),
    response_count: not_burned,
    color: "#4F71BE"
  }];
}

export function getHistoricalPresenceOfBurnoutRatio(results, burnout_blocks, filterSet = -1) {
  const data = [];
  const {burnoutBlocks} = burnout_blocks;

  burnoutBlocks.forEach(block => {
    const filtered = filterResults(results, filterSet, block.id);
    const total = filtered.length;
    let burned = 0, not_burned = 0;

    for (let result of filtered){
      const response = result.responses.find(r => r.question_id === parseInt(process.env.REACT_APP_BURNOUT_QUESTION));
      if (!response) continue;
      response.score > 50 ? not_burned++ : burned++ ;
    }

    const burned_freq = total > 0 ? (burned/total)*100 : 0;

    data.push({
      name: block.created_at,
      y: parseFloat(burned_freq.toFixed(process.env.REACT_APP_CHART_PRECISION)),
      response_count: burned,
      color: "#EA712B"
    });
  })
  //reverse order so that they show on chart in ascending chronological order
  data.reverse()
  console.log(data);
  return data;
}

//For the Average Score chart
export function getAverageScores(results, questions, blockId, filterSet = -1) {
  const filtered = filterResults(results, filterSet, blockId) ;
  const data = [];
  const aggData = new Map();

  for (let q of questions) {
    aggData.set(q.id,0)
  }

  const total = filtered.length;

  for (let result of filtered) {
    for (let response of result.responses) {
      if (aggData.has(response.question_id)) {
        const n = aggData.get(response.question_id);
        aggData.set(response.question_id, n+response.score);
      }
    }
  }

  aggData.forEach((value,key) => {
    const avg = total > 0 ? (value/total) : 0;
    //STANDARD DEVIATION
    //https://en.wikipedia.org/wiki/Standard_deviation
    const sd = Math.sqrt((filtered.reduce(
        (acc, curr) => {
          const response = curr.responses.find(r => r.question_id === key)
          if (response) {
            return(acc + Math.pow((curr.responses.find(r => r.question_id === key).score - avg),2))
          } else {
            // console.log(curr, value, key)
            return acc
          }
        }
        ,0)
    )/total-1);

    data.push({
      name: questions.find(q => q.id === key).question,
      order: questions.find(q => q.id === key).display_order,
      y: parseFloat(avg.toFixed(process.env.REACT_APP_CHART_PRECISION)),
      response_count: total,
      sd: parseFloat(sd.toFixed(process.env.REACT_APP_CHART_PRECISION))
    });
  });

  return data.sort((a,b) => (a.order - b.order));
}

export function getCurrUserResponse(results, question, blockId) {

  const {historicalRaw} = results;
  const data = historicalRaw[blockId.toString()]
  const currentUser = data && data.find(r => r.provider.hasOwnProperty("is_current_user") && r.provider["is_current_user"]);
  if (currentUser) {
    return  currentUser.responses.find(r => question && r.question_id === question.id);
  } else {
    return false;
  }
}

// For the response count shown with every chart
export function getNumFilteredResponses(results,blockId,filterSet=-1) {
  const filtered = filterResults(results, filterSet, blockId);
  return filtered.length;
}

function getNumResponses(results, blockId) {
  const {historicalRaw} = results;
  const data = historicalRaw[blockId.toString()]
  return data && data.length ? data.filter(r => r.responses && r.responses.hasOwnProperty('length') && r.responses.length > 0).length : 0;
}

function ageRangeToStringForTables(ageRange) {
  return ageRange[0] === defaultAgeMin ?
    "<" + (ageRange[1]+1) :
    (ageRange[1] >= 100 ?
      ">" + (ageRange[0]-1) :
      "" + ageRange[0] + "-" + ageRange[1])
}

// FOR SUMMARY TABLES
export function getRespondentCharacteristics(results, demographics, blockId) {
  const {locations, specialties, genders, licenses} = demographics;
  // const responses = historicalRaw[blockId.toString()]
  const dataSort = (a,b) => (b.number-a.number);

  const total = getNumResponses(results, blockId);

  // age ranges are different then the filters for this table per stephanie's request
  // const tableAgeRanges = [[0,19],[20,29],[30,39],[40,49],[50,59],[60,100]];


  const ageData = ageRanges.map(ageRange => {
    const filters = [Object.assign({}, initialFilters, {ageMax: ageRange[1], ageMin: ageRange[0]})];
    const num = getNumFilteredResponses(Object.assign({},results,{filters}),blockId,0);
    return {
      category: "age",
      subcategory: ageRangeToStringForTables(ageRange),
      number: num,
      percent: ((num/total)*100).toFixed(process.env.REACT_APP_CHART_PRECISION)
    };
  });

  const genderData = genders.filter(l => l !== 'All').map(gender => {
    const num = getNumFilteredResponses(
      Object.assign({},results,{filters:[Object.assign({}, initialFilters, {gender})]}),
      blockId,
      0
    );
    return {
      // header: i===0 ? 'Location' : '',
      category: "gender",
      subcategory: gender,
      number: num,
      percent: ((num/total)*100).toFixed(process.env.REACT_APP_CHART_PRECISION)
    };
  }).sort(dataSort);

  const locationData = locations.filter(l => l !== 'All').map(location => {
    const num = getNumFilteredResponses(
      Object.assign({},results,{filters:[Object.assign({}, initialFilters, {location})]}),
      blockId,
      0
    );
    return {
      // header: i===0 ? 'Location' : '',
      category: "location",
      subcategory: location,
      number: num,
      percent: ((num/total)*100).toFixed(process.env.REACT_APP_CHART_PRECISION)
    };
  });
  // .sort(dataSort);

  const specialtyData = specialties.filter(s => s !== 'All').map(specialty => {
    const num = getNumFilteredResponses(
      Object.assign({},results,{filters:[Object.assign({}, initialFilters, {specialty})]}),
      blockId,
      0
    );
    return {
      // header: i===0 ? 'Medical Specialty' : '',
      category: "specialty",
      subcategory: specialty,
      number: num,
      percent: ((num/total)*100).toFixed(process.env.REACT_APP_CHART_PRECISION)
    }
  });

  const licenseData = licenses.filter(s => s !== 'All').map(license => {
    const num = getNumFilteredResponses(
      Object.assign({},results,{filters:[Object.assign({}, initialFilters, {license})]}),
      blockId,
      0
    );
    return {
      // header: i===0 ? 'Medical Specialty' : '',
      category: "license",
      subcategory: license,
      number: num,
      percent: ((num/total)*100).toFixed(process.env.REACT_APP_CHART_PRECISION)
    }
  });
  // .sort(dataSort);
  return {ageData, genderData, locationData, specialtyData, licenseData};
}

//This is for the Respondent Presence of Burnout table
function getPresenceOfBurnout(results, blockId) {
  // const {raw,filters} = results;
  let burned = 0, total = 0;

  const res = filterResults(results,0, blockId);

  for (let result of res){
    const response = result.responses.find(r => r.question_id === parseInt(process.env.REACT_APP_BURNOUT_QUESTION));
    if (!response) continue;
    else if (response.score <= 50) burned++;
    total++;
  }
  return ((burned/total)*100).toFixed(process.env.REACT_APP_CHART_PRECISION);
}

export function getRespPresenceOfBurnout(results, demographics, blockId) {
  const {locations, specialties, genders, licenses} =  demographics;

  const dataSort = (a,b) => (b.percent-a.percent);

  //OVERALL
  const overallData = [{
    category: 'overall',
    subcategory: '',
    percent: getPresenceOfBurnout(
      Object.assign({},results,{filters:[initialFilters]}),
      blockId
    ),
    num: getNumResponses(results, blockId)
  }];

  //AGE (YEARS)
  const ageData = ageRanges.filter(ar => (ar[0] !== defaultAgeMin || ar[1] !== defaultAgeMax)).map(ageRange => {
    const ageFilters = Object.assign({}, initialFilters, {ageMax: ageRange[1], ageMin: ageRange[0]});

    return {
      category: "age",
      subcategory: ageRangeToStringForTables(ageRange),
      percent: getPresenceOfBurnout(
        Object.assign({},results,{filters: [ageFilters]}),
        blockId
      ),
      num: getNumFilteredResponses(
        Object.assign({},results,{filters: [ageFilters]}),
        blockId,
        0
      )
    };
  });

  //GENDERS
  const genderData = genders.filter(l => l !== 'All').map(gender => {
    const genderFilters = Object.assign({}, initialFilters, {gender});

    return {
      // header: i===0 ? 'Location' : '',
      category: "gender",
      subcategory: gender,
      percent: getPresenceOfBurnout(Object.assign({},results,{filters: [genderFilters]}),blockId),
      num: getNumFilteredResponses(Object.assign({},results,{filters: [genderFilters]}),blockId,0)
    };
  }).sort(dataSort);

  //LOCATIONS
  const locationData = locations.filter(l => l !== 'All').map(location => {
    const locationFilters = Object.assign({}, initialFilters, {location});

    return {
      // header: i===0 ? 'Location' : '',
      category: "location",
      subcategory: location,
      percent: getPresenceOfBurnout(Object.assign({},results,{filters: [locationFilters]}),blockId),
      num: getNumFilteredResponses(Object.assign({},results,{filters: [locationFilters]}),blockId,0)
    };
  });
  // .sort(dataSort);

  //MEDICAL SPECIALTIES
  const specialtyData = specialties.filter(s => s !== 'All').map(specialty => {
    const specialtyFilters = Object.assign({}, initialFilters, {specialty});

    return {
      // header: i===0 ? 'Medical Specialty' : '',
      category: "specialty",
      subcategory: specialty,
      percent: getPresenceOfBurnout(Object.assign({},results,{filters: [specialtyFilters]}),blockId),
      num: getNumFilteredResponses(Object.assign({},results,{filters: [specialtyFilters]}),blockId,0)
    }
  });
  // .sort(dataSort);

  const licenseData = licenses.filter(l => l !== 'All').map(license => {
    const licenseFilters = Object.assign({}, initialFilters, {license});

    return {
      // header: i===0 ? 'Medical Specialty' : '',
      category: "license",
      subcategory: license,
      percent: getPresenceOfBurnout(Object.assign({},results,{filters: [licenseFilters]}),blockId),
      num: getNumFilteredResponses(Object.assign({},results,{filters: [licenseFilters]}),blockId,0)
    }
  })

  return {overallData, ageData, genderData, locationData, specialtyData, licenseData};
}

export function getRespScores(results, questions, demographics, blockId) {
  const {locations, specialties, genders} = demographics;

  const scoreSort = (a,b) => (a.order-b.order);

  //OVERALL
  const overallData = [{
    category: "overall",
    subcategory: null,
    scores: getAverageScores(Object.assign({},results,{filters: [initialFilters]}),questions,blockId,0).sort(scoreSort),
    num: getNumResponses(results, blockId)
  }];

  //AGE (YEARS)
  const ageData = ageRanges.filter(ar => ar[0] !== defaultAgeMin || ar[1] !== defaultAgeMax).map(ageRange => {
    const ageFilters = Object.assign({}, initialFilters, {ageMax: ageRange[1], ageMin: ageRange[0]});

    //kind of a hacked way to reuse the averageScore function for the charts
    return {
      category: "age",
      subcategory: ageRangeToStringForTables(ageRange),
      scores: getAverageScores(Object.assign({},results,{filters: [ageFilters]}),questions,blockId,0).sort(scoreSort),
      num: getNumFilteredResponses(Object.assign({},results,{filters: [ageFilters]}),blockId,0)
    }
  });

  //GENDERS
  const genderData = genders.filter(l => l !== 'All').map(gender => {
    const gendersFilters = Object.assign({}, initialFilters, {gender});
    return {
      // header: i===0 ? 'Location' : '',
      category: "gender",
      subcategory: gender,
      scores: getAverageScores(Object.assign({},results,{filters: [gendersFilters]}),questions,blockId,0).sort(scoreSort),
      num: getNumFilteredResponses(Object.assign({},results,{filters: [gendersFilters]}),blockId,0)
    };
  });

  //LOCATIONS
  const locationData = locations.filter(l => l !== 'All').map(location => {
    const locationFilters = Object.assign({}, initialFilters, {location});
    return {
      // header: i===0 ? 'Location' : '',
      category: "location",
      subcategory: location,
      scores: getAverageScores(Object.assign({},results,{filters: [locationFilters]}),questions,blockId,0).sort(scoreSort),
      num: getNumFilteredResponses(Object.assign({},results,{filters: [locationFilters]}),blockId,0)
    };
  });

  //MEDICAL SPECIALTIES
  const specialtyData = specialties.filter(s => s !== 'All').map(specialty => {
    const specialtyFilters = Object.assign({}, initialFilters, {specialty});
    return {
      // header: i===0 ? 'Medical Specialty' : '',
      category: "specialty",
      subcategory: specialty,
      scores: getAverageScores(Object.assign({},results,{filters: [specialtyFilters]}),questions,blockId,0).sort(scoreSort),
      num: getNumFilteredResponses(Object.assign({},results,{filters: [specialtyFilters]}),blockId,0)
    }
  });

  return {overallData, ageData, genderData, locationData, specialtyData};
}