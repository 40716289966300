import React, { useState } from 'react';
import "./hubspot_support_widget.scss";
import { Button } from 'react-bootstrap';
import { IoMdHelpCircleOutline } from "react-icons/io";
import useIsMobile from '../../UtilityReactComponents/use_is_mobile';

const HubspotSupportWidget = () => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const isMobile = useIsMobile();

    const loadScript = () => {
        if (!scriptLoaded) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = 'hs-script-loader';
            script.async = true;
            script.defer = true;
            script.src = '//js.hs-scripts.com/45389480.js';

            // Append the script to the body
            document.body.appendChild(script);
            setScriptLoaded(true);

            // Optionally, you can add an onload event to handle any post-load logic
            script.onload = () => {
                // Your logic here after the script is loaded, if needed
            };
        }
    };

    return (
        <Button 
            className='hs-cta-trigger-button'
            onClick={loadScript} // Call loadScript on button click
            style={{bottom: isMobile ? 50 : 20}}
        >
            <IoMdHelpCircleOutline size={20} style={{ marginRight: 5, marginBottom: 1 }} />
            Support
        </Button>
    );
};

export default HubspotSupportWidget;