import React, {useEffect} from "react";
import {Route, Routes, useLocation, BrowserRouter, useNavigate} from "react-router-dom";
import {setGlobalNavigate} from "./_config/history.config";
import "./App.scss";

import asyncComponent from "./_helpers/AsyncComponent";
import RequireAuth from "./require_auth";
import OauthRedirect from "./oauth_redirect";
import TermsAndConditions from "./terms_and_conditions";
import CeraVeRedirect from "./CeraVeRedirect";
import ExternalVideoPage from "./MainPage/Body/EnrichmentCenter/ExternalVideoPage/external_video_page.jsx"
import {getCurrentBreakpoint} from "./_helpers/misc_functions";

const AsyncLogin = asyncComponent(() => import("./LoginPage"));
const AsyncConfirmPassword = asyncComponent(() => import("./LoginPage/ConfirmPasswordPage"));
const AsyncMain = asyncComponent(() => import("./MainPage"));
const AsyncAdmin = asyncComponent(() => import("./AdminPage"));
const AsyncForgotPassword = asyncComponent(() => import("./LoginPage/ForgotPasswordPage"));
const AsyncResetPassword = asyncComponent(() => import("./LoginPage/ResetPasswordPage"));
const AsyncCreateUser = asyncComponent(() => import("./LoginPage/CreateUserPage"));
const AsyncRemindMeLater = asyncComponent(() => import("./LoginPage/RemindMeLater"));
const AsyncRegistration = asyncComponent(() => import("./LoginPage/RegistrationPage"));
const AsyncReview = asyncComponent(() => import("./LoginPage/RegistrationPage/review_page"));
const AsyncBilling = asyncComponent(() => import("./LoginPage/RegistrationPage/billing_page"))
const AsyncPilotSurveyPage = asyncComponent(() => import("./PilotSurveyPage"));
const AsyncSurveyCompletionPage = asyncComponent(() => import("./PilotSurveyPage/survey_completion"));
const AsyncSingleSignOnPage = asyncComponent(() => import("./LoginPage/SingleSignOnPage"));
const AsyncResendSurveyPage = asyncComponent(() => import("./LoginPage/ResendSurveyPage"));

// This component makes the app scroll the window to the top on any navigation
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [pathname]); // Runs whenever the route changes

  return null;
};

// New wrapper component to store global navigate function
const RouterWrapper = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setGlobalNavigate(navigate);
  }, [navigate]); // Ensures the function is set when the component mounts

  useEffect(() => {
    const handleResize = () => {
      console.log("Current Bootstrap Breakpoint:", getCurrentBreakpoint());
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Log the initial breakpoint

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <AppRoutes />;
};

const AppRoutes = () => (
  <>
    <ScrollToTop/>
    <Routes>
      <Route path="/login" element={<AsyncLogin/>}/>
      <Route path="/forgot-password" element={<AsyncForgotPassword/>}/>
      <Route path="/reset-password" element={<AsyncResetPassword/>}/>
      <Route path="/resend-survey" element={<AsyncResendSurveyPage/>}/>
      {/*specialized route for the 3m modal workflow*/}
      <Route path="/remind-me-later/:auth_token" element={<AsyncRemindMeLater/>}/>
      <Route path="/confirm-password/:token/:email" element={<AsyncConfirmPassword/>}/>
      <Route path="/register" element={<AsyncRegistration/>}/>
      <Route path="/billing" element={<AsyncBilling/>}/>
      <Route path={"/review_registration"} element={<AsyncReview/>}/>
      <Route path="/create-ec-user/MUSC"
             element={<AsyncCreateUser org_name={"MUSC"} practice_id={46} block_id={72} is_musc_org={true}/>}/>
      <Route path="/create-ec-user/Prisma"
             element={<AsyncCreateUser org_name={"Prisma"} practice_id={47} block_id={73}/>}/>
      {/*
          specialized user creation page for ANA.
          block_id is hardcoded for specific survey event and
          will need to be updated for future survey events.
          */}
      {/* TODO removed need to hardcode block id*/}
      <Route path="/create-ec-user/ANA"
             element={<AsyncCreateUser org_name={"ANA"} practice_id={54} block_id={123} is_ana_org={true}/>}/>
      <Route path="/create-ec-user/CPP" element={<AsyncCreateUser is_cpp_org={true} block_id={121} practice_id={39}/>}/>
      <Route path="/create-ec-user/chamberlain"
             element={<AsyncCreateUser org_name={"Chamberlain University"} practice_id={77} block_id={114}
                                       is_chamberlain_org={true}/>}/>
      {/*
          specialized user creation page for LGH.
          block_id is hardcoded for specific survey event and
          will need to be updated for future survey events.
          */}
      <Route path="/create-ec-user/LGH"
             element={<AsyncCreateUser org_name={"Lancaster General Health"} practice_id={78} block_id={126}/>}/>
      <Route path="/create-ec-user/CeraVe" element={<CeraVeRedirect/>}/>
      {/*
        route for free promotional users
        TODO rake task that disables users 1 year after their creation date
        */}
      <Route path="/create-ec-user/promotion" element={<AsyncCreateUser practice_id={91}/>}/>
      {/*
          generic user creation page for Enrichment Center only.
          mainly used for the a la carte selling of the Enrichment Center through authorized.net
           */}
      <Route path="/create-ec-user/:org_name?" element={<AsyncCreateUser practice_id={43}/>}/>
      <Route exact path="/assessment" element={<AsyncPilotSurveyPage/>}/>
      <Route exact path="/complementary_assessment" element={<AsyncPilotSurveyPage/>}/>
      <Route path="/complementary_assessment/:group/:email?" element={<AsyncPilotSurveyPage/>}/>
      <Route path="/assessment/:group/:email?" element={<AsyncPilotSurveyPage/>}/>
      <Route path="/complementary_assessment_completed" element={<AsyncSurveyCompletionPage/>}/>
      <Route path="/assessment_completed" element={<AsyncSurveyCompletionPage/>}/>
      <Route path={"/oauth"} element={<OauthRedirect/>}/>
      <Route path={"/single-sign-on"} element={<AsyncSingleSignOnPage/>}/>
      <Route path={"/terms-and-conditions"} element={<TermsAndConditions/>}/>
      <Route path={"/external_video_page"} element={<ExternalVideoPage/>}/>
      <Route path="/admin/*"
             element={
               <RequireAuth>
                 <AsyncAdmin/>
               </RequireAuth>
             }
      />
      <Route path={"*"}
             element={
               <RequireAuth>
                 <AsyncMain/>
               </RequireAuth>
             }
      />
    </Routes>
  </>
);

const App = () => {
  // grab frontend and backend versions and report on them
  useEffect(() => {
    console.log(`FRONTEND VERSION: ${process.env.REACT_APP_COMMIT_ID} (BRANCH: ${process.env.REACT_APP_BRANCH_NAME})`);
    fetch(`${process.env.REACT_APP_API_URL}/health_check`)
    .then(response => response.json())
    .then(data => {
      const { commit_id, branch_name } = data.info;
      console.log(`BACKEND VERSION: ${commit_id} (BRANCH: ${branch_name})`);
    })
    .catch(error => console.error('Error fetching backend health check:', error));
  }, []);

  return (
    <BrowserRouter>
      <RouterWrapper/>
    </BrowserRouter>
  );
}

export default App;