let globalNavigate = null;

export const setGlobalNavigate = (navigateFn) => {
  globalNavigate = navigateFn;
};

/**
 * Navigates using a location object or a string path.
 * @param {stringt} to - The path string or location object.
 * @param {boolean} [replace=false] - Whether to replace the current entry in history.
 * @param {object} state
 */
export const navigateTo = (to, replace = false, state = undefined) => {
  if (globalNavigate) {
    globalNavigate(to, { replace, state });
  } else {
    console.error("Navigation function is not initialized yet.");
  }
};