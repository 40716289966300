import {Card} from "react-bootstrap";
import styles from "../video_page.module.scss";
import React from "react";

const References = (props) => {
  const {main} = props;
  const references = main.references ? main.references.split("|") : [];
  const reference_urls = main.reference_urls ? main.reference_urls.split("|") : [];
  if (references && references.length > 0) {
    return (
      <Card className={styles.sideCard}>
        <Card.Header className={`${styles.objectivesHeader} ${styles.open}`}>
          References
        </Card.Header>
        <Card.Body className={styles.cardBody}>
          <ul className={styles.referenceList}>
            {
              references.map((reference, index) => (
                <li key={index}>{reference_urls && reference_urls.length > 0 && reference_urls[index] ?
                  <a href={reference_urls[index]}>{reference.trim()}</a> : <p>{reference.trim()}</p>}</li>
              ))
            }
          </ul>
        </Card.Body>
      </Card>
    )
  } else {
    return null;
  }
}

export default References;