import axios from "axios";
import {store} from "../index";
import {deviceDetect} from "react-device-detect";

export const logEvent = (event, context=null, seconds_watched=null) => {
  const storeState = store.getState();
  const device = deviceDetect();
  const { accessToken, client, email } = storeState.authentication;

  axios.post(`${process.env.REACT_APP_API_URL}/api/log_frontend_utilization`, {
    event,
    context,
    seconds_watched,
    current_path: window.location.pathname + window.location.search,
    device,
    session_id: accessToken
  },{
    headers: {
      uid: email,
      "access-token": accessToken,
      client: client
    }
  })
    .then(function (response) {
      // console.log('LOG SUCCESS!',response);
    })
    .catch(function (error) {
      console.error("ERROR LOGGING FRONTEND UTILIZATION", error.response);
      if (error.response && error.response.data && error.response.data.errors && Array.isArray(error.response.data.errors) && (error.response.data.errors.includes("Authorized users only.") ||
        error.response.data.errors.includes("You need to sign in or sign up before continuing."))) {
        localStorage.clear();
      } else if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request, error.message);
      } else {
        console.log(error.message);
      }
    })
    .finally(function () {
      //  will run regardless of then or catch
    });
}

export const logAchievement = (name, icon=null) => {
  const storeState = store.getState();
  // const device = deviceDetect();
  const awardedDate = new Date();


  const { accessToken, client, email } = storeState.authentication;
  axios.post(`${process.env.REACT_APP_API_URL}/api/achievements`, {
    name: name,
    icon: icon,
    awarded_at: awardedDate

  },{
    headers: {
      uid: email,
      "access-token": accessToken,
      client: client
    }
  })
  .then(function (response) {
    // console.log('LOG SUCCESS!',response);
  })
  .catch(function (error) {
    console.error("ERROR LOGGING ACHIEVEMENT", error.response);
    if (error.response) {
      console.log(error.response);
    } else if (error.request) {
      console.log(error.request,error.message);
    } else {
      console.log(error.message);
    }
  })
  .finally(function () {
  //  will run regardless of then or catch
  });
}