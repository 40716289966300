import { useEffect } from 'react';

const RedirectComponent = () => {

  useEffect(() => {
    window.location.href = "https://www.sehealthcaresolutions.com/";
  }, []);

  return null;
};

export default RedirectComponent;