import { useState, useEffect } from 'react';

// Custom hook to detect mobile screen size
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
   
      setIsMobile(window.document.documentElement.clientWidth <= 768); // Adjust threshold based on your needs
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
  }, []);

  return isMobile;
};

export default useIsMobile;
