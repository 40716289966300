import {Card} from "react-bootstrap";
import styles from "../video_page.module.scss";
import React from "react";

const LearningObjectives = (props) => {
  const {main} = props;
  if (main && (main.objective_1 || main.objective_2 || main.objective_3)) {
    //weird bug not letting ol display
    return (
      <Card className={styles.sideCard}>
        <Card.Header className={`${styles.objectivesHeader} ${styles.open}`}>
          Learning Objectives
        </Card.Header>
        <Card.Body className={styles.cardBody}>
          
          <ul className={styles.objectivesList}>
            {main.objective_1 && <li>{main.objective_1}</li>}
            {main.objective_2 && <li>{main.objective_2}</li>}
            {main.objective_3 && <li>{main.objective_3}</li>}
          </ul>
        </Card.Body>
      </Card>
    );
  } else {
    return null
  }
}

export default LearningObjectives;