import {
  COMMENTS_ANALYSIS_FETCH_FAILURE,
  COMMENTS_ANALYSIS_FETCH_SUCCESS,
  FETCHING_COMMENTS_ANALYSIS,
  RESET_BURNOUT_BLOCK,
  SET_COMMENTS_FILTERS,
  RESET_GROUP
} from "../_actions/_types.actions"
import {ageRanges, num_results_needed} from "./results_reducer";
import {yearsInNursingWyoming, yearsInProfession} from "../../_helpers/user_options";

const initialState = {
  licenseFilter: 'All',
  specialtyFilter: 'All',
  locationFilter: 'All',
  priorityFilter: 'All',
  genderFilter: 'All',
  ageRangeOptions: [[-1, -1], ...ageRanges],
  ageMin: -1,
  ageMax: -1,
  fetchingCommentsAnalysis: false,
  commentsAnalysisFetchError: null,
  status: "open",
  comments: [],
  headings: {
    1: "",
    2: "",
    3: ""
  },
  show_subcategories: false,
  disclaimers: {
    commentsAnalysis: "",
    frequencyAnalysis: "",
    qualitativeAnalysis: ""
  }
}

export default function comments_analysis(state = initialState, action) {
  switch (action.type) {
    case FETCHING_COMMENTS_ANALYSIS:
      return Object.assign({}, state, {fetchingCommentsAnalysis: action.status});
    case COMMENTS_ANALYSIS_FETCH_SUCCESS:
      return Object.assign(
        {},
        state,
        action.data.analysis);
    case COMMENTS_ANALYSIS_FETCH_FAILURE:
      return Object.assign({}, state, {commentsAnalysisFetchError: true})
    case SET_COMMENTS_FILTERS:
      return Object.assign({}, state, action.filters);
    case RESET_BURNOUT_BLOCK:
      return Object.assign({}, initialState);
    case RESET_GROUP:
      return Object.assign({}, initialState);
    default:
      return state
  }
}

function filterComments(comments_analysis) {
  const {
    comments,
    licenseFilter,
    priorityFilter,
    genderFilter,
    specialtyFilter,
    locationFilter,
    ageMin,
    ageMax
  } = comments_analysis
  return comments.filter(c =>
    ((ageMin === -1 && ageMax === -1) || (parseInt(c.age) <= ageMax && parseInt(c.age) >= ageMin)) &&
    (licenseFilter === 'All' || c.provider_title_name === licenseFilter) &&
    (specialtyFilter === 'All' || c.specialty === specialtyFilter) &&
    (locationFilter === 'All' || c.location === locationFilter) &&
    (priorityFilter === 'All' || c.stressor_level === priorityFilter) &&
    (genderFilter === 'All' || c.gender === genderFilter)
  );
}

export function getCommentsDemographics(analysis, block_id) {
  const {comments} = analysis;
  const licenses = new Set();
  const specialties = new Set();
  const locations = new Set();
  const priorities = new Set();
  const genders = new Set();
  if (Array.isArray(comments)) {
    // console.log
    comments.forEach(comment => {
        licenses.add(comment.provider_title_name);
        priorities.add(comment.stressor_level);
        specialties.add(comment.specialty);
        locations.add(comment.location);
        genders.add(comment.gender);
      }
    );
  }

  licenses.delete('Physician');
  licenses.delete('Nurse');
  let specialtiesOverride = [];
  if (block_id === 111) { // Wyoming School Nurses
    specialtiesOverride = yearsInNursingWyoming;
  } else if (block_id === 103 || block_id === 96 || block_id === 119 || block_id === 123) { // ANA
    specialtiesOverride = ['All', ...yearsInProfession];
  } else {
    specialtiesOverride = ["All", ...[...specialties].filter(r => r !== null).sort()]
  }
  return {
    licenseOptions: [
      "All",
      // "Physician",
      // "Nurse",
      ...[...licenses].filter(r => r !== null).sort()
    ],
    specialtyOptions: specialtiesOverride,
    locationOptions: [
      "All",
      ...[...locations].filter(r => r !== null).sort()
    ],
    priorityOptions: [
      'All',
      ...[...priorities].filter(r => r !== null).sort()
    ],
    genderOptions: [
      'All',
      ...[...genders].filter(r => r !== null).sort()
    ]
  }
}

export function filterCommentsByAttribute(comments_analysis, block_id) {

  const results = {}
  let total = 0

  function add_topic(topic, comment) {
    const {
      stressor_level: priority,
      text_response: text,
      license_type,
      provider_title_name,
      specialty,
      location,
      age,
      gender,
      show
    } = comment

    // add topic if currently not present
    if (results[topic] === undefined) {
      results[topic] = {
        'comments': [],
        'summary': {"number": 0, "percent": ''}
      }
    }

    // if topic is present with subcategories, but current comment was not assigned a subtopic
    if (results[topic]['comments'] === undefined) {
      results[topic] = {
        'comments': [],
        'summary': {"number": 0, "percent": ''}
      }
    }

    results[topic]['comments'].push({
      priority,
      text,
      license_type,
      provider_title_name,
      specialty,
      location,
      age,
      gender,
      show
    })
    total += 1
  }


  function add_subtopic(subtopic, topic, comment) {
    const {
      stressor_level: priority,
      text_response: text,
      license_type,
      provider_title_name,
      specialty,
      location,
      age,
      gender,
      show
    } = comment

    // add topic if currently not present
    if (results[topic] === undefined) {
      results[topic] = {
        'subcategories': {},
        'summary': {"number": 0, "percent": ''}
      }
    }

    // add subtopic if currently not present
    if (results[topic]['subcategories'][subtopic] === undefined) {
      results[topic]['subcategories'][subtopic] = {
        'comments': [],
        'summary': {"number": 0, "percent": 0}
      }
    }

    results[topic]['subcategories'][subtopic]['comments'].push({
      priority,
      text,
      license_type,
      provider_title_name,
      specialty,
      location,
      age,
      gender,
      show
    })
    total += 1
  }

  function addSummaryData() {
    if (Object.keys(results).length === 0 && results.constructor === Object) return false
    const categories = Object.keys(results)

    categories.forEach(category => {
      if (results[category]['subcategories'] === undefined) {
        const categoryTotal = results[category]['comments'].length

        results[category]['summary'] = {
          'number': categoryTotal,
          'percent': `${(parseFloat(categoryTotal) / total * 100).toFixed(parseInt(process.env.REACT_APP_CHART_PRECISION))}%`
        }
      } else {
        const subcategories = Object.keys(results[category]['subcategories'])
        let categoryTotal = 0

        subcategories.forEach(subcategory => {
          const subcategoryTotal = results[category]['subcategories'][subcategory]['comments'].length
          results[category]['subcategories'][subcategory]['summary'] = {
            'number': subcategoryTotal,
            'percent': `${(parseFloat(subcategoryTotal) / total * 100).toFixed(parseInt(process.env.REACT_APP_CHART_PRECISION))}%`
          }
          categoryTotal += subcategoryTotal
        })

        results[category]['summary'] = {
          'number': categoryTotal,
          'percent': `${(parseFloat(categoryTotal) / total * 100).toFixed(parseInt(process.env.REACT_APP_CHART_PRECISION))}%`
        }
      }
    })
  }

  const filteredComments = filterComments(comments_analysis);

  filteredComments.forEach(comment => {
    // use the old topics for certain older blocks for consistency
    if ([96, 97, 72].includes(block_id)) {
      for (let i = 0; i < 3; i++) {
        const topic = comment[`topic_${i + 1}`]
        const subtopic = comment[`subtopic_${i + 1}`]
        if (topic && subtopic === null) {
          add_topic(topic, comment)
        } else if (topic && subtopic) {
          add_subtopic(subtopic, topic, comment)
        }
      }
    } else {
      comment.categories.forEach(category => add_topic(category, comment))
    }
  });

  addSummaryData()


  // Sort Categories
  let entries = Object.entries(results)
  let sorted = entries.sort((a, b) => {
    if (a[1]['subcategories'] !== undefined) {
      let subEntries = Object.entries(a[1]['subcategories'])
      a[1]['subcategories'] = subEntries.sort((c, d) => {
        return d[1]["summary"]["number"] - c[1]["summary"]["number"]
      }).reduce((acc, curr) => Object.assign(acc, {[curr[0]]: curr[1]}), {})
    }
    return b[1]["summary"]["number"] - a[1]["summary"]["number"]
  })
  return sorted.reduce((acc, curr) => Object.assign(acc, {[curr[0]]: curr[1]}), {})
}

export function getPossibleCommentsFilters(comments_analysis, block_id) {
  // since there are 3 possible responses per user, bump up the threshold to make sure comments are not identifiable
  const num_comments_needed = num_results_needed * 2;
  const {
    comments,
    licenseFilter,
    genderFilter,
    specialtyFilter,
    locationFilter,
    ageMin,
    ageMax,
    ageRangeOptions
  } = comments_analysis
  const {
    licenseOptions,
    specialtyOptions,
    locationOptions,
    genderOptions
  } = getCommentsDemographics(comments_analysis, block_id)
  const genders = genderOptions.filter(gen =>
    new Set(filterComments({
      comments,
      licenseFilter,
      priorityFilter: 'All',
      genderFilter: gen,
      specialtyFilter,
      locationFilter,
      ageMin,
      ageMax
    }).map(c => c.id)).size >= num_comments_needed
  )

  const locations = locationOptions.filter(loc =>
    new Set(filterComments({
      comments,
      licenseFilter,
      priorityFilter: 'All',
      genderFilter,
      specialtyFilter,
      locationFilter: loc,
      ageMin,
      ageMax
    }).map(c => c.id)).size >= num_comments_needed
  )

  const specialties = specialtyOptions.filter(spec =>
    new Set(filterComments({
      comments,
      licenseFilter,
      priorityFilter: 'All',
      genderFilter,
      specialtyFilter: spec,
      locationFilter,
      ageMin,
      ageMax
    }).map(c => c.id)).size >= num_comments_needed
  )

  const licenses = licenseOptions.filter(lic =>
    new Set(filterComments({
      comments,
      licenseFilter: lic,
      priorityFilter: 'All',
      genderFilter,
      specialtyFilter,
      locationFilter,
      ageMin,
      ageMax
    }).map(c => c.id)).size >= num_comments_needed
  )

  const age_ranges = ageRangeOptions.filter(ar =>
    new Set(filterComments({
      comments,
      licenseFilter,
      priorityFilter: 'All',
      genderFilter,
      specialtyFilter,
      locationFilter,
      ageMin: ar[0],
      ageMax: ar[1]
    }).map(c => c.id)).size >= num_comments_needed
  )

  console.log({
    genders,
    age_ranges,
    licenses,
    locations,
    specialties
  })


  return {
    genders,
    age_ranges,
    licenses,
    locations,
    specialties
  }

}