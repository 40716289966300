import {logEvent} from "../../../../../_helpers/UtilizationTracking";
import {Card} from "react-bootstrap";
import styles from "../video_page.module.scss";
import React from "react";

const RecommendedReading = (props) => {
  const {main} = props;
  const info = main;
  const rec = [];
  // rec content 1
  if (info.recommended_reading_1) {
    if (info.s3_url_recommended_reading_1) {
      rec.push((
        <li key={1}>
          <a href={info.s3_url_recommended_reading_1} target={"_blank"} rel="noopener noreferrer"
             onClick={() => logEvent("clicked recommended reading", info.s3_url_recommended_reading_1)}>
            {info.recommended_reading_1}
          </a>
        </li>));
    } else if (info.web_url_recommended_reading_1) {
      rec.push((
        <li key={1}>
          <a href={info.web_url_recommended_reading_1} target={"_blank"} rel="noopener noreferrer"
             onClick={() => logEvent("clicked recommended reading", info.web_url_recommended_reading_1)}>
            {info.recommended_reading_1}
          </a>
        </li>));
    } else if (info.recommended_reading_1_doc) {
      rec.push((
        <li key={1}>
          <a href={`${process.env.REACT_APP_S3_URL}/${info.recommended_reading_1_doc}`} target={"_blank"}
             rel="noopener noreferrer"
             onClick={() => logEvent("clicked recommended reading", info.recommended_reading_1_doc)}>
            {info.recommended_reading_1}
          </a>
        </li>));
    } else {
      rec.push((
        <li key={1}>
          {info.recommended_reading_1}
        </li>
      ))
    }
  }
  // rec content 2
  if (info.recommended_reading_2) {
    if (info.s3_url_recommended_reading_2) {
      rec.push((
        <li key={2}>
          <a href={info.s3_url_recommended_reading_2} target={"_blank"} rel="noopener noreferrer"
             onClick={() => logEvent("clicked recommended reading", info.s3_url_recommended_reading_2)}>
            {info.recommended_reading_2}
          </a>
        </li>));
    } else if (info.web_url_recommended_reading_2) {
      rec.push((
        <li key={2}>
          <a href={info.web_url_recommended_reading_2} target={"_blank"} rel="noopener noreferrer"
             onClick={() => logEvent("clicked recommended reading", info.web_url_recommended_reading_2)}>
            {info.recommended_reading_2}
          </a>
        </li>));
    } else if (info.recommended_reading_2_doc) {
      rec.push((
        <li key={2}>
          <a href={`${process.env.REACT_APP_S3_URL}/${info.recommended_reading_2_doc}`} target={"_blank"}
             rel="noopener noreferrer"
             onClick={() => logEvent("clicked recommended reading", info.recommended_reading_2_doc)}>
            {info.recommended_reading_2}
          </a>
        </li>));
    } else {
      rec.push((
        <li key={2}>
          {info.recommended_reading_2}
        </li>
      ))
    }
  }
  //rec content 3
  if (info.recommended_reading_3) {
    if (info.s3_url_recommended_reading_1) {
      rec.push((
        <li key={3}>
          <a href={info.s3_url_recommended_reading_3} target={"_blank"} rel="noopener noreferrer"
             onClick={() => logEvent("clicked recommended reading", info.s3_url_recommended_reading_3)}>
            {info.recommended_reading_3}
          </a>
        </li>));
    } else if (info.web_url_recommended_reading_3) {
      rec.push((
        <li key={3}>
          <a href={info.web_url_recommended_reading_3} target={"_blank"} rel="noopener noreferrer"
             onClick={() => logEvent("clicked recommended reading", info.web_url_recommended_reading_3)}>
            {info.recommended_reading_3}
          </a>
        </li>));
    } else if (info.recommended_reading_3_doc) {
      rec.push((
        <li key={3}>
          <a href={`${process.env.REACT_APP_S3_URL}/${info.recommended_reading_3_doc}`} target={"_blank"}
             rel="noopener noreferrer"
             onClick={() => logEvent("clicked recommended reading", info.recommended_reading_3_doc)}>
            {info.recommended_reading_3}
          </a>
        </li>));
    } else {
      rec.push((
        <li key={3}>
          {info.recommended_reading_3}
        </li>
      ))
    }
  }

  return (
    rec.length > 0 &&
    <Card className={styles.sideCard}>
      <Card.Header className={`${styles.objectivesHeader} ${styles.open}`}>
        Recommended Reading
      </Card.Header>
      <Card.Body className={styles.cardBody}>
        <ul className={styles.readingList}>
          {rec}
        </ul>
      </Card.Body>
    </Card>
  )
}

export default RecommendedReading;