const personalEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "icloud.com",
    "mail.com",
    "protonmail.com",
    "zoho.com",
    "yandex.com",
    "gmx.com",
    "me.com",
    "msn.com",
    "live.com",
    "mac.com",
    "fastmail.com"
];


const singleSignOnDomains = [
    'prismahealth.org',
    'stjude.org'
];

function getDayOfYear() {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 0);
    const diff = now - start + (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
}

function seededRandom(seed) {
    const x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
}

function shouldShowSurvey(lastSurveyTimestamp) {
    const currentTime = new Date();
    const lastSurveyTime = new Date(lastSurveyTimestamp);

    // Calculate the number of days since the last survey
    const daysSinceSurvey = Math.floor((currentTime - lastSurveyTime) / (1000 * 60 * 60 * 24));

    // Cap the chance at 100% after 10 days
    const surveyChance = Math.min(daysSinceSurvey * 10, 100);

    // Use the day of the year as a seed
    const dayOfYear = getDayOfYear();

    // Create a seed based on the day of the year and the lastSurveyTimestamp
    const seed = dayOfYear + lastSurveyTime.getTime();

    // Generate a seeded random number between 0 and 100
    const randomChance = seededRandom(seed) * 100;

    // console.log(daysSinceSurvey, surveyChance, dayOfYear, seed, randomChance);

    // Return true if the seeded random chance is less than the survey chance
    return randomChance < surveyChance;
}

function shuffleArrayWithSeed(array) {
    let seed = getDayOfYear();
    let result = array.slice(); // Copy the array
    for (let i = result.length - 1; i > 0; i--) {
        const j = Math.floor(seededRandom(seed + i) * (i + 1));
        [result[i], result[j]] = [result[j], result[i]]; // Swap elements
    }
    return result;
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
}

const dateFormatter = (dateString = '') => {

    let re = /(\d{4})-(\d{2})-(\d{2})(\s|T)(\d{2}):(\d{2}):(\d{2})/;
    const result = dateString.match(re);
    if (result) {
        return new Date(result[1], parseInt(result[2]) - 1, result[3], result[5], result[6])
    } else {
        return
    }

}

const formatUnixTimestamp = (unixTimestamp) => {
    const date = new Date(Number(unixTimestamp)); // Convert Unix timestamp to milliseconds
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
};

function isPersonalEmail(email) {
    return personalEmailDomains.some(domain => email.endsWith(`@${domain}`));
}

function isSingleSignOnEmail(email) {
    return singleSignOnDomains.some(domain => email.endsWith(`@${domain}`));
}

const isValidEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

function interpolateColor(color1, color2, factor) {
    if (arguments.length < 3) {
        factor = 0.5;
    }
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - result[i]));
    }
    return result;
}

function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
}

function rgbToHex(r, g, b) {
    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function getColorGradient(percent, color1, color2, color3) {
    let startColor, endColor;
    let adjustedPercent;

    if (percent <= 0.5) {
        // First half of the gradient (color1 to color2)
        startColor = color1;
        endColor = color2;
        adjustedPercent = percent * 2; // Adjust range to [0, 1]
    } else {
        // Second half of the gradient (color2 to color3)
        startColor = color2;
        endColor = color3;
        adjustedPercent = (percent - 0.5) * 2; // Adjust range to [0, 1]
    }

    const interpolatedColor = interpolateColor(startColor, endColor, adjustedPercent);
    return rgbToHex(interpolatedColor[0], interpolatedColor[1], interpolatedColor[2]);
}

function hexToRgba(hex, alpha) {
    // Remove the hash symbol if present
    hex = hex.replace(/^#/, '');

    // Parse the hex string into RGB components
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Return the RGBA string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function sumContentLengths(content) {
    let totalSeconds = 0;

    content.forEach(c => {
        if (c && c.length && typeof c.length === 'string' && c.length.includes(':')) {
            const [minutes, seconds] = c.length.split(':').map(Number);

            if (!isNaN(minutes) && !isNaN(seconds)) {
                totalSeconds += (minutes * 60) + seconds;
            }
        }
      
    });

    const totalMinutes = Math.floor(totalSeconds / 60) % 60;
    const totalHours = Math.floor(Math.floor(totalSeconds / 60) / 60);
    const remainingSeconds = totalSeconds % 60;

    // Format minutes and seconds to ensure two digits
    const formattedMinutes = String(totalMinutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${totalHours > 0 ? String(totalHours) + ':' : ''}${formattedMinutes}:${formattedSeconds}`;
}

function convertTimeToSeconds(timeString) {

    if (!timeString || typeof timeString !== 'string') {
        console.error('Invalid input: timeString must be a non-empty string');
        return null;
    }

    const parts = timeString.split(':');

    if (parts.length !== 2) {
        console.error('Invalid time format. Expected format is "mm:ss"');
        return null;
    }

    const [minutes, seconds] = parts.map(Number);

    if (isNaN(minutes) || isNaN(seconds)) {
        console.error('Invalid numbers in timeString:', timeString);
        return null;
    }

    return (minutes * 60) + seconds;
}

// this function is used for the Max Length content filter
const convertSecondsToTime = (seconds) => {
    // const hours = Math.floor(seconds / 3600);
    // const minutes = Math.floor((seconds % 3600) / 60);
    const minutes = Math.floor(seconds / 60);
    // const secs = seconds % 60;

    return `${minutes} min`;
    // if (hours > 0) {
        // return `${hours} h ${minutes}m`;
    // } else if (minutes > 0) {
    //     return `${minutes} min`;
    // } else {
    //     return `${secs} sec`;
    // }
};

// this function returns true if the two given arrays have any 1 item in common
const hasCommonItem = (arr1, arr2) => arr1.some(item => arr2.includes(item));

const decodeEntities = (function() {
    // this prevents any overhead from creating the object each time
    const element = document.createElement('div');

    function decodeHTMLEntities (str) {
        if(str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }

        return str;
    }

    return decodeHTMLEntities;
})();

const breakpoints = {
    xs: 0,   // Extra small (default)
    sm: 576, // Small
    md: 768, // Medium
    lg: 992, // Large
    xl: 1200, // Extra Large
    xxl: 1400 // Extra Extra Large (Bootstrap 5+)
};

const getCurrentBreakpoint = () => {
    const width = window.innerWidth;

    if (width >= breakpoints.xxl) return "xxl";
    if (width >= breakpoints.xl) return "xl";
    if (width >= breakpoints.lg) return "lg";
    if (width >= breakpoints.md) return "md";
    if (width >= breakpoints.sm) return "sm";
    return "xs"; // Default for < 576px
};

export {
    dateFormatter,
    isValidEmail,
    getColorGradient,
    hexToRgba,
    isPersonalEmail,
    personalEmailDomains,
    getWindowDimensions,
    formatUnixTimestamp,
    sumContentLengths,
    isSingleSignOnEmail,
    shuffleArray,
    shuffleArrayWithSeed,
    shouldShowSurvey,
    decodeEntities,
    convertTimeToSeconds,
  convertSecondsToTime,
  getCurrentBreakpoint,
  hasCommonItem,
};